import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer';

import styles from './Drawer.module.css';

interface Props extends Omit<MuiDrawerProps, 'classes'> {}

const Drawer = ({ children, ...props }: Props) => {
  return (
    <MuiDrawer
      {...props}
      classes={{
        root: styles.root,
      }}
    >
      {children}
    </MuiDrawer>
  );
};

export default Drawer;
