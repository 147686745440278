import {
  faFacebook,
  faGooglePlus,
  faInstagram,
  faLinkedin,
  faPinterest,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IconButton from 'client/elements/IconButton';
import { EmailIcon, LocationIcon, PhoneIcon } from 'client/elements/Icons';
import Link from 'client/elements/Link';
import Typography from 'client/elements/Typography';

import { useTranslation } from 'client/utils/i18n';

import styles from './Footer.module.css';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className={styles.container}>
      <div className={styles.contactsWrapper}>
        <div className={styles.contacts}>
          <Typography>
            <Link
              color="textPrimary"
              href={`tel:${t('main:gabari_phone_short')}`}
              className={styles.contactsItem}
              aria-label="phone"
            >
              <PhoneIcon className={styles.contactsIcon} />
              <span>
                {t('main:individuals_data')}
                {t('main:gabari_phone')}
              </span>
            </Link>
            <Link
              color="textPrimary"
              href={`tel:${t('main:gabari_phone_professionals_short')}`}
              className={styles.contactsItem}
              aria-label="phone"
            >
              <PhoneIcon className={styles.contactsIcon} />
              <span>
                {t('main:professionals_data')}
                {t('main:gabari_phone_professionals')}
              </span>
            </Link>
          </Typography>
          <div className={styles.contactsItem}>
            <LocationIcon className={styles.contactsIcon} />
            <Typography>
              {' '}
              <span>
                {t('main:gabari_name_official')}
                <br />
                {t('main:gabari_address')}
                <br />
                {t('main:gabari_postcode')} {t('main:gabari_city')}
                <br />
                {t('main:gabari_country')}
              </span>
            </Typography>
          </div>
          <Typography>
            <Link
              color="textPrimary"
              href={`mailto:${t('main:gabari_email')}`}
              className={styles.contactsItem}
              aria-label="mailto"
            >
              <EmailIcon className={styles.contactsIcon} />
              <span>{t('main:gabari_email')}</span>
            </Link>
          </Typography>
        </div>
      </div>

      <ul className={styles.social}>
        {[
          {
            link: 'https://www.facebook.com/credence.inox.cuisine',
            icon: faFacebook,
          },
          {
            link: 'https://www.instagram.com/credence_inox.com_',
            icon: faInstagram,
          },
          {
            link: 'https://twitter.com/Credenceinox',
            icon: faTwitter,
          },
          {
            link: 'https://plus.google.com/111458417157480312937',
            icon: faGooglePlus,
          },
          {
            link: 'https://www.pinterest.fr/credenceinox',
            icon: faPinterest,
          },
          {
            link: 'https://www.linkedin.com/company/credence-inox',
            icon: faLinkedin,
          },
        ].map(({ link, icon }) => {
          return (
            <li key={link} className={styles.socialItem}>
              <Typography>
                <IconButton
                  component="a"
                  color="inherit"
                  aria-label="socials"
                  // @ts-ignore: link props
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="large"
                >
                  <FontAwesomeIcon icon={icon} />
                </IconButton>
              </Typography>
            </li>
          );
        })}
      </ul>

      <Typography paragraph className={styles.legal}>
        <Link color="primary" href="/terms-conditions">
          {t('main:terms_short')}
        </Link>
        {' - '}
        <Link color="primary" href="/privacy">
          {t('main:privacy_title')}
        </Link>
        {' - '}
        <Link color="primary" href="/shipping">
          {t('main:delivery')}
        </Link>
        {' - '}
        <Link color="primary" href="/legal-notice">
          {t('main:legal_notice_title')}
        </Link>
        {' - '}
        <Link color="primary" href="/about">
          {t('main:about_credence_inox')}
        </Link>
        {' - '}
        <Link color="primary" href="https://www.credence-inox.com/blog">
          {t('main:our_partners')}
        </Link>
        {' - '}
        <Link color="primary" href="/pages/6/foire-aux-questions">
          {t('main:frequently_asked_questions')}
        </Link>
      </Typography>

      <Typography paragraph className={styles.copyright}>
        © {new Date().getFullYear()} credence-inox.com
      </Typography>
    </footer>
  );
};

export default Footer;
