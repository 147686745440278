import MuiListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';

import { LinkBehavior, LinkBehaviorProps } from 'client/elements/LinkBehavior';

export const ListItemButton: React.ComponentType<Omit<ListItemButtonProps, 'href'>> =
  MuiListItemButton;

export const ListItemButtonAsLink = (props: ListItemButtonProps & LinkBehaviorProps) => (
  <MuiListItemButton {...props} component={LinkBehavior} />
);
