import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useState } from 'react';

import IconButton from 'client/elements/IconButton';
import { AccountCircleIcon } from 'client/elements/Icons';
import Link from 'client/elements/Link';
import Menu from 'client/elements/Menu';
import MenuItem from 'client/elements/MenuItem';
import Typography from 'client/elements/Typography';

import useCart from 'client/hooks/useCart';
import useSignIn from 'client/hooks/useSignIn';
import useUserGroup from 'client/hooks/useUserGroup';

import { useTranslation } from 'client/utils/i18n';
import { makeCss } from 'client/utils/styles';

const useCss = makeCss(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  userName: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export interface UserMenuProps {
  toggleSaveCart: () => void;
}
export const UserMenu = ({ toggleSaveCart }: UserMenuProps) => {
  const css = useCss();
  const { t } = useTranslation();
  const router = useRouter();
  const { openSignInDialog } = useSignIn();
  const { onUserChange } = useCart();
  const session = useSession();
  const group = useUserGroup();

  const [accountMenu, setAccountMenu] = useState<HTMLElement | null>(null);

  return (
    <>
      <div css={css.wrapper}>
        <IconButton
          aria-owns={accountMenu ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          aria-label="menu"
          onClick={event => setAccountMenu(event.currentTarget)}
          color="inherit"
          size="large"
        >
          <AccountCircleIcon />
        </IconButton>
        {session.data?.user && (
          <Typography color="inherit" css={css.userName}>
            {session.data.user.lastname}
          </Typography>
        )}
      </div>
      <Menu
        id="menu-appbar"
        onClose={() => setAccountMenu(null)}
        anchorEl={accountMenu}
        open={!!accountMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {session.data?.user && (
          <Link href="/account" color="inherit" underline="none">
            <MenuItem onClick={() => setAccountMenu(null)}>{t('main:account_page_title')}</MenuItem>
          </Link>
        )}
        {group.id > 1 && group.id !== 40 && (
          <MenuItem
            onClick={() => {
              toggleSaveCart();
              setAccountMenu(null);
            }}
          >
            {t('main:save_cart')}
          </MenuItem>
        )}
        {group.id > 1 && group.id !== 40 && (
          <Link href="/carts" color="inherit" underline="none">
            <MenuItem onClick={() => setAccountMenu(null)}>{t('main:manage_carts')}</MenuItem>
          </Link>
        )}
        {(session.data?.user.role === 'administrator' ||
          session.data?.user.role === 'developer') && (
          <Link href="/admin" color="inherit" underline="none">
            <MenuItem onClick={() => setAccountMenu(null)}>{t('main:admin')}</MenuItem>
          </Link>
        )}
        {session.data?.user && (
          <MenuItem
            onClick={async () => {
              setAccountMenu(null);
              await signOut({ redirect: false });
              onUserChange({ action: 'sign-out' });
            }}
          >
            {t('main:sign_out')}
          </MenuItem>
        )}
        {!session.data?.user && (
          <Link href={`/create-account?returnTo=${router.asPath}`} color="inherit" underline="none">
            <MenuItem onClick={() => setAccountMenu(null)}>{t('main:sign_up')}</MenuItem>
          </Link>
        )}
        {!session.data?.user && (
          <MenuItem
            onClick={() => {
              openSignInDialog();
              setAccountMenu(null);
            }}
          >
            {t('main:sign_in')}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
