import { Popper, styled } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useCallback, useId, useLayoutEffect, useMemo, useRef, useState } from 'react';

import Badge from 'client/elements/Badge';
import Button from 'client/elements/Button';
import IconButton from 'client/elements/IconButton';
import {
  KeyboardArrowRightRoundedIcon,
  MenuIcon,
  PhoneIcon,
  ShoppingCartIcon,
} from 'client/elements/Icons';
import Link from 'client/elements/Link';
import { LinkBehavior } from 'client/elements/LinkBehavior';
import ListItem from 'client/elements/ListItem';
import { ListItemButtonAsLink } from 'client/elements/ListItemButton';
import ListItemText from 'client/elements/ListItemText';
import MenuItem from 'client/elements/MenuItem';
import Paper from 'client/elements/Paper';
import Toolbar from 'client/elements/Toolbar';
import Typography from 'client/elements/Typography';

import useCart from 'client/hooks/useCart';
import useUserGroup from 'client/hooks/useUserGroup';

import { useTranslation } from 'client/utils/i18n';
import { makeCss } from 'client/utils/styles';

import Logo from './Logo';
import styles from './ToolBars.module.css';
import { UserMenu } from './UserMenu';

const useCss = makeCss(theme => ({
  brandLogo: {
    height: '30px',

    [theme.breakpoints.up('md')]: {
      margin: '0 15px 0 0',
    },
  },

  menu: {
    zIndex: theme.zIndex.appBar,
  },
  menuCard: {
    maxWidth: 350,
    overflowY: 'auto',
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.primary.main}`,
    padding: '0 4px',
  },
}));

export interface ToolBarsProps {
  links: Array<{ text: string; href: string; target?: '_blank' }>;
  toggleDrawer: () => void;
  toggleSaveCart: () => void;
}
const ToolBars = ({ links, toggleDrawer, toggleSaveCart }: ToolBarsProps) => {
  const css = useCss();
  const { t } = useTranslation();
  const { cartItems, loading } = useCart();
  const group = useUserGroup();

  const primaryToolbar = useRef<HTMLDivElement>(null);
  const secondaryToolbar = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useLayoutEffect(() => {
    const observer = new ResizeObserver(([primaryToolbar, secondaryToolbar]) => {
      const primaryHeight = primaryToolbar?.contentRect.height || 0;
      const secondaryHeight = secondaryToolbar?.contentRect.height || 0;
      setHeaderHeight(primaryHeight + secondaryHeight);
    });

    if (primaryToolbar.current) observer.observe(primaryToolbar.current);
    if (secondaryToolbar.current) observer.observe(secondaryToolbar.current);
  }, []);

  return (
    <>
      <Toolbar className={styles.toolbar} ref={primaryToolbar}>
        <IconButton
          className={styles.menuButton}
          color="inherit"
          onClick={toggleDrawer}
          size="large"
        >
          <MenuIcon />
        </IconButton>

        <div className={styles.main}>
          <Link href="/" color="inherit" underline="none">
            <div className={styles.brandWrapper}>
              <Logo css={css.brandLogo} />
              <div className={styles.brandTexts}>
                <Typography component="span" color="inherit" className={styles.brandName}>
                  CREDENCE-INOX.COM
                </Typography>
                <Typography component="span" color="inherit" className={styles.brandSlogan}>
                  Je mesure... Je commande... Je colle...
                </Typography>
              </div>
            </div>
          </Link>
        </div>

        <div className={styles.main}>
          <Typography component="span" color="inherit" className={styles.brandPro}>
            {group.displayName && group.name}
          </Typography>
        </div>

        <div className={[styles.main, styles.accountGroup].join(' ')}>
          <Button href={`tel:${t('main:gabari_phone_short')}`} color="inherit">
            <PhoneIcon className={styles.buttonIcon} />
            <span className={styles.phone}>{t('main:gabari_phone')}</span>
          </Button>

          <Link href="/cart" color="inherit">
            <IconButton color="inherit" aria-label="cart" size="large">
              {loading ? (
                <ShoppingCartIcon />
              ) : (
                <StyledBadge
                  badgeContent={cartItems.reduce((acc, cartItem) => acc + cartItem.quantity, 0)}
                  color="secondary"
                >
                  <ShoppingCartIcon />
                </StyledBadge>
              )}
            </IconButton>
          </Link>

          <div>
            <div className={styles.brandWrapper}>
              <UserMenu toggleSaveCart={toggleSaveCart} />
            </div>
          </div>
        </div>
      </Toolbar>
      <Toolbar className={styles.menu} ref={secondaryToolbar}>
        <Tabs
          centered={true}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          value={false}
        >
          {links.map((link, index) => (
            <MainMenuItem key={index} {...link} headerHeight={headerHeight} />
          ))}
        </Tabs>
      </Toolbar>
    </>
  );
};

const menuElevation = 12;

interface MenuItem {
  text: string;
  href: string;
  target?: '_blank';
  children?: MenuItem[];
}

interface MainMenuItemProps extends MenuItem {
  headerHeight: number;
}

function MainMenuItem({ text, href, target, children, headerHeight }: MainMenuItemProps) {
  const css = useCss();
  const menuId = useId();

  const menuCardStyles = useMemo(
    () => ({ maxHeight: `calc(100vh - ${headerHeight}px - 50px)` }),
    [headerHeight],
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);
  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
    [],
  );
  const handleClose = useCallback(() => setAnchorEl(null), []);

  return (
    <Tab
      aria-controls={open ? menuId : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      className={styles.menuTabRoot}
      component={LinkBehavior}
      href={href}
      target={target}
      label={
        <>
          {text}

          {children && children.length > 0 && (
            <Popper
              id={menuId}
              anchorEl={anchorEl}
              open={open}
              placement="bottom"
              aria-labelledby={menuId}
              css={css.menu}
            >
              <Paper elevation={menuElevation} css={css.menuCard} sx={menuCardStyles}>
                {children.map((child, index) => (
                  <SecondaryMenuItem key={index} {...child} headerHeight={headerHeight} />
                ))}
              </Paper>
            </Popper>
          )}
        </>
      }
      onMouseEnter={children && children.length > 0 ? handleOpen : undefined}
      onMouseLeave={children && children.length > 0 ? handleClose : undefined}
    />
  );
}

interface SecondaryMenuItemProps extends MenuItem {
  headerHeight: number;
}

function SecondaryMenuItem({ text: name, href, children, headerHeight }: SecondaryMenuItemProps) {
  const css = useCss();
  const menuId = useId();

  const menuCardStyles = useMemo(
    () => ({ maxHeight: `calc(100vh - ${headerHeight}px - 50px)` }),
    [headerHeight],
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);
  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
    [],
  );
  const handleClose = useCallback(() => setAnchorEl(null), []);

  return (
    <>
      <ListItem
        disablePadding
        onMouseEnter={children && children.length > 0 ? handleOpen : undefined}
        onMouseLeave={children && children.length > 0 ? handleClose : undefined}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <ListItemButtonAsLink href={href}>
          <ListItemText primary={name} />
          {children && children.length > 0 ? <KeyboardArrowRightRoundedIcon /> : undefined}
        </ListItemButtonAsLink>

        {children && children.length > 0 && (
          <Popper
            id={menuId}
            aria-labelledby={menuId}
            anchorEl={anchorEl}
            open={open}
            placement="right"
            css={css.menu}
          >
            <Paper elevation={menuElevation} css={css.menuCard} sx={menuCardStyles}>
              {children.map(child => (
                <SecondaryMenuItem
                  key={`menu-item-${child.text}`}
                  {...child}
                  headerHeight={headerHeight}
                />
              ))}
            </Paper>
          </Popper>
        )}
      </ListItem>
    </>
  );
}

export default ToolBars;
