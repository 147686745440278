import Rating from '@mui/material/Rating';
import { useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';
import useLocalStorageState from 'use-local-storage-state';

import { CancelIcon, KeyboardArrowLeftRoundedIcon } from 'client/elements/Icons';
import Typography from 'client/elements/Typography';

import { useTranslation } from 'client/utils/i18n';

import styles from './Promos.module.css';

const Promos = () => {
  const { t } = useTranslation();

  const reviewsValue = 4.9;

  return (
    <div className={styles.container}>
      <Promo
        id="reviews"
        text={
          <>
            <Rating name="read-only" value={reviewsValue} readOnly precision={0.5} size="small" />
            <Typography component="span" color="inherit" className={styles.startComment}>
              {t('main:promos_reviews', { value: reviewsValue })}
            </Typography>
          </>
        }
      />
    </div>
  );
};

const Promo = ({ id, text }: { id: string; text: ReactNode }) => {
  const theme = useTheme();

  const [open, setOpen] = useLocalStorageState(`promos-${id}`, { defaultValue: true });

  return (
    <div
      className={styles.promo}
      style={{
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <button className={styles.promoButton} onClick={() => setOpen(!open)}>
        {open ? <CancelIcon /> : <KeyboardArrowLeftRoundedIcon />}
      </button>

      <Typography
        className={`${styles.promoLabel} ${open ? '' : styles.promoLabelClose}`}
        color="inherit"
      >
        {text}
      </Typography>
    </div>
  );
};

export default Promos;
