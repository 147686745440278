import { Collapse } from '@mui/material';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';

import Button, { ButtonAsLink } from 'client/elements/Button';
import Divider from 'client/elements/Divider';
import Drawer from 'client/elements/Drawer';
import IconButton from 'client/elements/IconButton';
import { ExpandLessIcon, ExpandMoreIcon, KeyboardArrowRightIcon } from 'client/elements/Icons';
import List from 'client/elements/List';
import ListItem from 'client/elements/ListItem';
import { ListItemButtonAsLink } from 'client/elements/ListItemButton';
import ListItemText from 'client/elements/ListItemText';

import useSignIn from 'client/hooks/useSignIn';

import { useTranslation } from 'client/utils/i18n';

import styles from './Drawer.module.css';

interface MenuItem {
  text: string;
  href: string;
  target?: '_blank';
  children?: MenuItem[];
}

interface MenuItemProps extends MenuItem {
  indentation?: number;
}
function MenuItem({ text: name, href, target, children, indentation = 0 }: MenuItemProps) {
  const [open, setOpen] = useState(false);
  const toggle = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(prevState => !prevState);
  }, []);

  return (
    <>
      <ListItem disablePadding>
        <ListItemButtonAsLink href={href} sx={{ pl: 2 + 4 * indentation }} target={target}>
          <ListItemText primary={name} />
          {children && children.length > 0 ? (
            <IconButton onClick={toggle}>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          ) : undefined}
        </ListItemButtonAsLink>
      </ListItem>

      {children && children.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List disablePadding>
            {children.map(child => (
              <MenuItem key={`menu-item-${child.text}`} {...child} indentation={indentation + 1} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

const drawerWidth = 350;

interface ClippedDrawerProps {
  open: boolean;
  links: Array<MenuItem>;
  toggleDrawer: () => void;
}
const ClippedDrawer = ({ open, links: menuItems, toggleDrawer }: ClippedDrawerProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const session = useSession();
  const { openSignInDialog } = useSignIn();

  return (
    <>
      <Drawer
        open={open}
        onClose={() => toggleDrawer()}
        sx={{ '.MuiDrawer-paper': { width: '100%', maxWidth: drawerWidth } }}
      >
        <List className={styles.accountDetails}>
          {session.data?.user ? (
            <ListItem>
              <ButtonAsLink
                variant="outlined"
                color="secondary"
                href="/account"
                className={styles.accountDetailsButton}
              >
                <div>
                  <div>
                    {session.data.user.firstname} {session.data.user.lastname}
                  </div>
                  <div>
                    <small>{session.data.user.email}</small>
                  </div>
                </div>
                <span className={styles.accountDetailsIcon}>
                  <KeyboardArrowRightIcon />
                </span>
              </ButtonAsLink>
            </ListItem>
          ) : (
            <>
              <ListItem>
                <ButtonAsLink
                  href={`/create-account?returnTo=${router.asPath}`}
                  className={styles.accountButton}
                  color="primary"
                  variant="outlined"
                  fullWidth
                >
                  {t('main:sign_up')}
                </ButtonAsLink>
              </ListItem>
              <ListItem>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    openSignInDialog();
                    toggleDrawer();
                  }}
                  fullWidth
                >
                  {t('main:sign_in')}
                </Button>
              </ListItem>
            </>
          )}
        </List>
        <Divider />
        <List>
          {menuItems.map((menuItem, index) => (
            <MenuItem key={index} {...menuItem} />
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default ClippedDrawer;
