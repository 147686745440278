import MuiAppBar from '@mui/material/AppBar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useSession } from 'next-auth/react';
import React from 'react';

import useUserGroup from 'client/hooks/useUserGroup';

import { getTheme } from 'client/utils/styles';

import SaveCart from './SaveCart';
import ToolBars, { ToolBarsProps } from './ToolBars';

interface AppBarProps {
  links: ToolBarsProps['links'];
  toggleDrawer: () => void;
}

const AppBar = ({ links, toggleDrawer }: AppBarProps) => {
  const session = useSession();
  const group = useUserGroup();

  const [saveCart, setSaveCart] = React.useState(false);

  const toggleSaveCart = React.useCallback(() => setSaveCart(state => !state), []);

  return (
    <>
      <ThemeProvider theme={() => createTheme(getTheme(group.headerTheme))}>
        <MuiAppBar position="sticky" color="primary">
          <ToolBars links={links} toggleDrawer={toggleDrawer} toggleSaveCart={toggleSaveCart} />
        </MuiAppBar>
      </ThemeProvider>
      {session.data && (
        <SaveCart userId={session.data.user.idAsNumber} open={saveCart} onClose={toggleSaveCart} />
      )}
    </>
  );
};

export default AppBar;
